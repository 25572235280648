import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Invite = () => {
  const location = useLocation();

  useEffect(() => {
    const openPickApp = () => {
      const params = new URLSearchParams(location.search);

      const poolSeasonId = params.get('PoolSeasonId');
      const poolId = params.get('PoolId');

      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Enlaces para abrir la aplicación
      const androidLink = `com.delvalon.pick://join-pool-url?PoolSeasonId=${poolSeasonId}&PoolId=${poolId}`;
      const iosLink = `com.delvalon.pick://join-pool-url?PoolSeasonId=${poolSeasonId}&PoolId=${poolId}`;
      //const appStoreLink = 'https://apps.apple.com/app/idYOUR_APP_ID';
      //const playStoreLink = 'https://play.google.com/store/apps/details?id=com.delvalon.pick';

      // Detectar si es Android
      if (/android/i.test(userAgent)) {
        window.location = androidLink;
        setTimeout(() => {
          window.close();
          // window.location = playStoreLink; // Redirigir a Play Store si no se abre la app
        }, 2000);
      }
      // Detectar si es iOS
      else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location = iosLink;
        setTimeout(() => {
          window.close();
          // window.location = appStoreLink; // Redirigir a App Store si no se abre la app
        }, 2000);
      } else {
        window.location = 'https://pick.delvalon.com/'; // Cambia esto a tu enlace
      }
    };

    openPickApp();
  }, [location]);

  return (
    <div>
      <h1>Redirigiendo...</h1>
    </div>
  );
};

export default Invite;