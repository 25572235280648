import './App.css';

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import AprendePick from './components/AprendePick';
import ContactUs from './components/ContactUs';
import Invite from './components/Invite';
import NavBar from './components/NavBar';

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        {/* <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header> */}
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/aprende-pick" element={<AprendePick />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/invite" element={<Invite />} />
          </Routes>
      </Router>
    </div>
  );
}


// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           ¡Bienvenidos a Pick!
//         </p>
//         <p><i>La mejor forma de organizar tus quinielas</i></p>
//         {}
//       </header>
//     </div>
//   );
// }

export default App;
